@keyframes blinkTop {
  50% {
    border-top-color: rgba(62, 163, 247, 0.1);
  }
}
@keyframes blinkBottom {
  50% {
    border-bottom-color: rgba(62, 163, 247, 0.1);
  }
}
@keyframes blinkRight {
  50% {
    border-right-color: rgba(62, 163, 247, 0.1);
  }
}
@keyframes blinkLeft {
  50% {
    border-left-color: rgba(62, 163, 247, 0.1);
  }
}

.edge__description {
  margin: 40px 0;
  .front__model {
    margin: 0 auto;
    border: 5px solid;
    width: 9vw;
    height: 30vh;
    .edge {
      font-size: 18px;
      position: absolute;
    }

    &--left {
      border-color: #fcd7a0;
      background-color: rgba(252, 215, 160, 0.2);
      position: relative;
      z-index: 2;
      .edge__left {
        color: #ffbd40;
      }
      .edge__desc {
        position: absolute;
        top: 5px;
        left: -40px;
        letter-spacing: 2px;
        transform: rotate(-90deg);
        font-size: 16px;
        // font-weight: bold;
      }
      .w1l {
        top: 50%;
        left: -40px;
        transform: translateY(-50%);
      }
      .w2l {
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
      }
      .s1l {
        left: 50%;
        top: -30px;
        transform: translateX(-50%);
      }
      .s2l {
        left: 50%;
        bottom: 0px;
        transform: translateX(-50%);
      }
    }
    &--right {
      border: 5px solid;
      border-color: #73a52e;
      background-color: rgba(205, 231, 169, 0.3);
      position: absolute;
      top: 5px;
      left: 5px;
      z-index: 0;
      div {
        position: relative;
        width: 100%;
        height: 100%;
        .edgeright__desc {
          position: absolute;
          bottom: 10px;
          right: -45px;
          letter-spacing: 2px;
          transform: rotate(90deg);
          font-size: 16px;
        }
        .edge__right {
          color: #73a52e;
          // position: absolute;
        }
        .w1p {
          top: 50%;
          left: 5px;
          transform: translateY(-50%);
        }
        .w2p {
          top: 50%;
          right: -40px;
          transform: translateY(-50%);
        }
        .s1p {
          left: 50%;
          top: -5px;
          transform: translateX(-50%);
        }
        .s2p {
          left: 50%;
          bottom: -30px;
          transform: translateX(-50%);
        }
      }
    }
  }
  @media (max-width: 600px) {
    .front__model {
      width: 50vw;
      &--right {
        width: inherit;
      }
    }
  }
  .topEdge {
    border-top-color: rgb(62, 163, 247);
  }
  .topAnimation {
    animation: blinkTop 1s infinite linear;
  }
  .bottomEdge {
    border-bottom-color: rgb(62, 163, 247);
  }
  .bottomAnimation {
    animation: blinkBottom 1s infinite linear;
  }
  .rightEdge {
    border-right-color: rgb(62, 163, 247);
  }
  .rightAnimation {
    animation: blinkRight 1s infinite linear;
  }
  .leftEdge {
    border-left-color: rgb(62, 163, 247);
  }
  .leftAnimation {
    animation: blinkLeft 1s infinite linear;
  }
}
